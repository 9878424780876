import { __decorate } from "tslib";
import { Component, Inject, Vue, Watch, Emit, Ref } from 'vue-property-decorator';
import { CsvRecordPaginationActionEnum } from '@flosum-backup/contracts';
import { RecordViewModule, RecordViewModuleStore } from '@/store/modules/record-view';
import BackupObjectRecordFieldsModal from '@/components/backups/BackupObjectRecordFieldsModal.vue';
import BackupObjectRecordFilterModal from '@/components/backups/BackupObjectRecordFilterModal.vue';
import { createInitialPagination, createPaginationRequest } from '@/utils/csv-pagination';
import BackupsApi from '@/services/api/backups.api';
import RecordViewCache from '@/classes/record-view-cache';
let BackupObjectRecords = class BackupObjectRecords extends Vue {
    constructor() {
        super(...arguments);
        this.backupId = RecordViewModule.backup.id;
        this.objectName = RecordViewModule.backupObject.name;
        this.cache = RecordViewCache.getInstance(this.backupId);
        this.selectedFields = [];
        this.declarativeFilter = [];
        this.declarativeFilterCustom = '';
        this.pagination = createInitialPagination();
        this.records = [];
        this.expandedRows = [];
        this.isCreated = false;
    }
    get tableFields() {
        return [
            ...this.selectedFields.map(field => ({
                key: field.name,
                label: field.label,
                thStyle: {
                    minWidth: '250px',
                    maxWidth: '500px',
                },
            })),
            { key: 'actions', label: '', thStyle: { width: '50px' } },
        ];
    }
    created() {
        const cachedSettings = this.cache.get(this.objectName);
        if (cachedSettings) {
            // we cannot use simple assignment since link to the objects will be different
            this.selectedFields = this.backupObjectDescribe.fields.filter(field => !!cachedSettings.fields.find(cachedField => cachedField.name === field.name));
            this.declarativeFilter = cachedSettings.declarativeFilter.filters;
            this.declarativeFilterCustom = cachedSettings.declarativeFilter.customLogic;
        }
        else {
            this.selectedFields = this.backupObjectDescribe.fields.filter(field => field.name === 'Id');
        }
        // make sure that cached settings will not trigger fetching records until component mounted
        this.$nextTick(() => {
            this.isCreated = true;
        });
    }
    mounted() {
        this.fetchRecords();
    }
    onOpenRecord(record) {
        return record.Id;
    }
    onOpenRecordHistory(record) {
        return record.Id;
    }
    toggleRowExpand(index) {
        if (this.expandedRows.includes(index)) {
            this.expandedRows = this.expandedRows.filter(rowIndex => rowIndex !== index);
        }
        else {
            this.expandedRows = [...this.expandedRows, index];
        }
        const tableElement = this.table.$el.querySelector('table');
        if (tableElement) {
            // index + 1 because of head row
            tableElement.rows[index + 1].classList.toggle('backup-object-records-table-row-clipped');
        }
    }
    async showFiltersModal() {
        const result = await this.$modal.show(BackupObjectRecordFilterModal, {
            fields: this.backupObjectDescribe.fields,
            declarativeFilter: this.declarativeFilter,
            declarativeFilterCustomLogic: this.declarativeFilterCustom,
        });
        if (result) {
            this.declarativeFilter = result.declarativeFilter;
            this.declarativeFilterCustom = result.declarativeFilterLogic;
        }
    }
    async showFieldsModal() {
        const fields = await this.$modal.show(BackupObjectRecordFieldsModal, {
            fields: this.backupObjectDescribe.fields,
            selected: this.selectedFields,
            // TODO: make index fields to be disabled by default
            disabled: this.backupObjectDescribe.fields.filter(field => field.name === 'Id'),
        });
        if (fields) {
            this.selectedFields = fields;
        }
    }
    loadPreviousRecords() {
        this.fetchRecords(CsvRecordPaginationActionEnum.Prev);
    }
    loadNextRecords() {
        this.fetchRecords(CsvRecordPaginationActionEnum.Next);
    }
    onRecordsShouldRequested() {
        if (!this.isCreated) {
            return;
        }
        this.cache.set(this.objectName, {
            filters: this.declarativeFilter,
            customLogic: this.declarativeFilterCustom,
        }, this.selectedFields);
        this.$nextTick(() => {
            this.fetchRecords();
        });
    }
    async fetchRecords(action = CsvRecordPaginationActionEnum.Init) {
        try {
            this.$loading('Loading records from backup');
            this.records = [];
            this.expandedRows = [];
            const { records, csvRecordPaginationOptionResponse } = await BackupsApi.findBackupObjectRecords(this.backupId, this.objectName, {
                declarativeFilter: this.declarativeFilter,
                declarativeFilterLogic: this.declarativeFilterCustom,
                organizationId: this.organizationId,
                resultFields: this.selectedFields.map(field => field.name),
                csvRecordPaginationOptionRequest: createPaginationRequest(this.pagination, action),
                storageId: +this.$route.query.storageId,
            });
            this.records = records;
            this.pagination = csvRecordPaginationOptionResponse;
            this.$loading(false);
        }
        catch (error) {
            this.$toast.error(error.message);
            this.$loading(false);
        }
    }
};
__decorate([
    Inject()
], BackupObjectRecords.prototype, "organizationId", void 0);
__decorate([
    RecordViewModuleStore.State
], BackupObjectRecords.prototype, "backupObjectDescribe", void 0);
__decorate([
    Ref()
], BackupObjectRecords.prototype, "table", void 0);
__decorate([
    Emit()
], BackupObjectRecords.prototype, "onOpenRecord", null);
__decorate([
    Emit()
], BackupObjectRecords.prototype, "onOpenRecordHistory", null);
__decorate([
    Watch('selectedFields', { deep: true }),
    Watch('declarativeFilter', { deep: true }),
    Watch('declarativeFilterCustom')
], BackupObjectRecords.prototype, "onRecordsShouldRequested", null);
BackupObjectRecords = __decorate([
    Component({})
], BackupObjectRecords);
export default BackupObjectRecords;
