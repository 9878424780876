import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import DeclarativeFilter from '@/components/shared/DeclarativeFilter.vue';
import { DeclarativeFilterItem } from '@/classes/declarative-filter-item';
import VueModal from '@/classes/vue-modal';
let BackupObjectRecordFilterModal = class BackupObjectRecordFilterModal extends VueModal {
    constructor() {
        super(...arguments);
        this.isFilterTouched = false;
        this.filterModel = {
            filters: [],
            customLogic: '',
        };
    }
    get filterFields() {
        return this.fields.reduce((fields, field) => ({
            ...fields,
            [field.name]: field.type,
        }), {});
    }
    created() {
        this.filterModel = {
            filters: this.declarativeFilter.map(filter => new DeclarativeFilterItem(filter.field, filter.option, filter.value, this.filterFields)),
            customLogic: this.declarativeFilterCustomLogic,
        };
    }
    saveFilter() {
        if (this.filterModel.filters.some(filter => !filter.isValid)) {
            this.$toast.error('Please fill up all fields and options in declarative filters!');
            return;
        }
        this.isFilterTouched = true;
        this.close();
    }
    onModalHidden() {
        if (this.isFilterTouched) {
            this.resolved({
                declarativeFilter: this.filterModel.filters.map(filter => filter.toJSON()),
                declarativeFilterLogic: this.filterModel.customLogic,
            });
        }
        else {
            this.resolved(null);
        }
    }
};
__decorate([
    Prop()
], BackupObjectRecordFilterModal.prototype, "fields", void 0);
__decorate([
    Prop()
], BackupObjectRecordFilterModal.prototype, "declarativeFilter", void 0);
__decorate([
    Prop()
], BackupObjectRecordFilterModal.prototype, "declarativeFilterCustomLogic", void 0);
BackupObjectRecordFilterModal = __decorate([
    Component({
        components: { DeclarativeFilter },
    })
], BackupObjectRecordFilterModal);
export default BackupObjectRecordFilterModal;
