export function createInitialPagination() {
    return {
        finishIndexFile: 0,
        finishIndexRecord: 0,
        startIndexRecord: 0,
        startIndexFile: 0,
        isFirstPage: true,
        isLastPage: true,
    };
}
export function createPaginationRequest(pagination, action) {
    return {
        ...pagination,
        action,
    };
}
