import { __decorate } from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import VueModal from '@/classes/vue-modal';
let BackupObjectRecordFieldsModal = class BackupObjectRecordFieldsModal extends VueModal {
    constructor() {
        super(...arguments);
        this.fieldsModel = [];
    }
    closeWithoutSaving() {
        // restore initial component state
        this.fieldsModel = this.selected;
        this.close();
    }
    onModalHidden() {
        if (this.fieldsModel === this.selected) {
            this.resolved(null);
        }
        else {
            this.resolved(this.fieldsModel);
        }
    }
    onFieldsChanged() {
        this.fieldsModel = this.selected;
    }
};
__decorate([
    Prop()
], BackupObjectRecordFieldsModal.prototype, "fields", void 0);
__decorate([
    Prop()
], BackupObjectRecordFieldsModal.prototype, "selected", void 0);
__decorate([
    Prop()
], BackupObjectRecordFieldsModal.prototype, "disabled", void 0);
__decorate([
    Watch('selected', { deep: true, immediate: true })
], BackupObjectRecordFieldsModal.prototype, "onFieldsChanged", null);
BackupObjectRecordFieldsModal = __decorate([
    Component({})
], BackupObjectRecordFieldsModal);
export default BackupObjectRecordFieldsModal;
