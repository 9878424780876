import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
let BackupObjectInfo = class BackupObjectInfo extends Vue {
};
__decorate([
    Prop()
], BackupObjectInfo.prototype, "name", void 0);
__decorate([
    Prop()
], BackupObjectInfo.prototype, "numberOfRecords", void 0);
__decorate([
    Prop()
], BackupObjectInfo.prototype, "queryWhereClause", void 0);
BackupObjectInfo = __decorate([
    Component({})
], BackupObjectInfo);
export default BackupObjectInfo;
