export default class RecordViewCache {
    // eslint-disable-next-line no-useless-constructor
    constructor(backupId) {
        this.backupId = backupId;
        this.cache = {};
    }
    static getInstance(backupId) {
        if (!RecordViewCache.instance || RecordViewCache.instance.backupId !== backupId) {
            RecordViewCache.instance = new RecordViewCache(backupId);
        }
        return RecordViewCache.instance;
    }
    get(objectName) {
        return this.cache[objectName] || null;
    }
    set(objectName, declarativeFilter, fields) {
        this.cache[objectName] = {
            fields,
            declarativeFilter,
        };
    }
}
