import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import BackupObjectInfo from '@/components/backups/BackupObjectInfo.vue';
import { RecordViewModuleStore } from '@/store/modules/record-view';
import BackupObjectRecords from '@/components/backups/BackupObjectRecords.vue';
let BackupObjectRecordsPage = class BackupObjectRecordsPage extends Vue {
    goBack() {
        this.$router
            .push({
            name: 'backups.backup.objects',
        })
            .catch(() => false);
    }
    openRecord(recordId) {
        this.$router
            .push({
            name: 'backups.backup.objects.object.records.record',
            params: {
                recordId,
            },
            query: {
                ...this.$route.query,
            },
        })
            .catch(() => false);
    }
    openRecordHistory(recordId) {
        this.$router
            .push({
            name: 'backups.backup.objects.object.records.record.history',
            params: {
                recordId,
            },
            query: {
                ...this.$route.query,
            },
        })
            .catch(() => false);
    }
};
__decorate([
    RecordViewModuleStore.State
], BackupObjectRecordsPage.prototype, "backupObject", void 0);
BackupObjectRecordsPage = __decorate([
    Component({
        components: {
            BackupObjectRecords,
            BackupObjectInfo,
        },
    })
], BackupObjectRecordsPage);
export default BackupObjectRecordsPage;
